.page-result {
  position: relative;
  max-width: 450px;
  margin: 0 auto;
}
.page-result .main-canvas {
  display: none;
}
.page-result .main-image img {
  width: 100%;
  height: auto;
  /* box-shadow: 0 0 10px #ccc; */
}