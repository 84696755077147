.share-hint {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2FB1F8;
  display: flex;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
@media screen and (min-width: 750px) {
  .share-hint {
    width: 450px;
    left: 50%;
    margin-left: -225px;
  }
}
.share-hint .qrcode {
  margin: 15px 10px 10px 15px;
}
.share-hint .main {
  margin: 15px 0 0 0;
  color: #fff;
  font-size: 12px;
}
.share-hint .main > div {
  font-size: 16px;
  font-weight: bolder;
}